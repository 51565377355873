import IconButton from "@mui/material/IconButton";
import { SxProps } from "@mui/system";
import Box from "@mui/system/Box";
import React, { MouseEventHandler, PropsWithChildren } from "react";

export interface CarouselNavButtonProps {
  fullHeightHover?: boolean;
  sx?: SxProps;
  ariaLabel?: string;
  visible?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const CarouselNavButton = (props: PropsWithChildren<CarouselNavButtonProps>) => {
  const { fullHeightHover = true, sx, ariaLabel, visible = true, onClick, children } = props;

  return (
    <Box
      sx={{
        position: "absolute",
        height: "100px",
        backgroundColor: "transparent",
        zIndex: 1,
        top: "calc(50% - 70px)",
        "&:hover": {
          "& $button": {
            backgroundColor: "black",
            filter: "brightness(120%)",
            opacity: "0.4",
          },
        },
        ...(fullHeightHover && {
          height: "100%",
          top: "0",
        }),
        ...sx,
      }}
    >
      <IconButton
        sx={{
          margin: "0 10px",
          position: "relative",
          backgroundColor: "background.paper",
          top: "calc(50% - 20px) !important",
          color: "white",
          fontSize: "30px",
          transition: "200ms",
          cursor: "pointer",
          opacity: visible ? 1 : 0,
          "&:hover": {
            opacity: "0.6 !important",
          },
        }}
        aria-label={ariaLabel}
        onClick={onClick}
      >
        {children}
      </IconButton>
    </Box>
  );
};
