import Box from "@mui/material/Box";
import { SxProps } from "@mui/system/styleFunctionSx";
import React from "react";
import { Image } from "../../core/Image";

export interface CardProps {
  className?: string;
  sx?: SxProps;
  name?: string;
  image: Queries.ImageSharp;
}

export const Card = (props: CardProps) => {
  const { className, sx, name, image } = props;

  return (
    <Box
      className={className}
      width={{ xs: "7.25rem", md: "17.5rem" }}
      height={{ xs: "7.25rem", md: "17.5rem" }}
      flexShrink={0}
      sx={sx}
    >
      <Image image={image.gatsbyImageData} alt={name} style={{ pointerEvents: "none", userSelect: "none" }} />
    </Box>
  );
};
