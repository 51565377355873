import { graphql, PageProps } from "gatsby";
import React from "react";
import { Layout } from "../components/layout/layout";
import { AboutSection } from "../components/sections/About";
import { BannerSection } from "../components/sections/Banner";
import { CardsRowSection } from "../components/sections/CardsRow";
import { ContactSection } from "../components/sections/Contact";
import { ExpertisesSection } from "../components/sections/Expertises";
import { PartnersSection } from "../components/sections/Partners";
import { SEO } from "../components/SEO";
import { HomepageData } from "../data/index";

const { bannerData, cardsRowData, aboutData, expertisesData, partnersData, contactData } = HomepageData;

export const pageQuery = graphql`
  query IndexPage {
    gamesBanners: allFile(filter: { sourceInstanceName: { eq: "games" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 328)
        }
      }
    }
    customerLogos: allFile(filter: { sourceInstanceName: { eq: "partners" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 300)
        }
      }
    }
  }
`;

interface IndexPageProps extends PageProps {
  data: Queries.IndexPageQuery;
}

const IndexPage = (props: IndexPageProps) => {
  const { gamesBanners, customerLogos } = props.data;

  const logos = customerLogos.nodes
    .map((node) => ({
      name: node.name,
      image: node.childImageSharp as Queries.ImageSharp,
    }))
    .filter((item) => item.image);

  const games = gamesBanners.nodes
    .map((node) => ({
      name: node.name,
      data: node.childImageSharp as Queries.ImageSharp,
    }))
    .filter((item) => !!item);

  return (
    <Layout>
      <SEO />
      <BannerSection {...bannerData} />
      <CardsRowSection images={games} {...cardsRowData} />
      <AboutSection {...aboutData} />
      <ExpertisesSection {...expertisesData} />
      <PartnersSection logos={logos} {...partnersData} />
      <ContactSection {...contactData} />
    </Layout>
  );
};

export default IndexPage;
