import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { chunk } from "lodash";
import React from "react";
import { Carousel } from "../../core/Carousel";
import { Image } from "../../core/Image";
import { Section } from "../../core/Section";
import { SectionTitle } from "../../core/SectionTitle";
import { Letter } from "../../graphics";

interface CustomerLogo {
  name: string;
  image: Queries.ImageSharp;
}

export interface PartnersSectionData {
  sectionId: string;
  title: string;
  logosPerSlice: number;
}

export interface PartnersSectionProps extends PartnersSectionData {
  logos: CustomerLogo[];
}

export const PartnersSection = (props: PartnersSectionProps) => {
  const { sectionId, title, logos, logosPerSlice } = props;

  const groups = chunk(logos, logosPerSlice);

  return (
    <Section sectionId={sectionId} disablePadding={true} sx={{ paddingTop: { xs: 8, md: 17.5 } }}>
      <Container maxWidth="lg">
        <SectionTitle
          title={title}
          Stroke={<Letter.P />}
          strokeContainerProps={{ left: { xs: "0rem", md: "-9rem" }, top: { xs: "-5rem", md: "-13rem" } }}
        />
      </Container>
      <Box bgcolor="background.paper" mt={{ xs: 4, md: 8 }} py={{ xs: 5, md: 8 }}>
        <Carousel
          showNavigationButtons="onHover"
          showIndicators="never"
          showProgressBar={false}
          sx={{ maxWidth: "87.5rem", minHeight: { xs: "21,4375rem", md: "15.625rem" }, marginX: "auto" }}
        >
          {groups.map((group, idx) => (
            <Grid
              key={idx}
              container={true}
              direction="row"
              spacing={{ xs: 2, md: 10 }}
              alignItems="center"
              sx={{ px: { xs: 2, md: 10 } }}
            >
              {group.map((item) => (
                <Grid key={item.name} xs={6} md={3} item={true} style={{ display: "flex", justifyContent: "center" }}>
                  <Image image={item.image.gatsbyImageData} alt={item.name} />
                </Grid>
              ))}
            </Grid>
          ))}
        </Carousel>
      </Box>
    </Section>
  );
};
