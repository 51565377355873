import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { ReactNode } from "react";
import { AppLink } from "../../../types";
import { Section } from "../../core/Section";
import { SectionTitle } from "../../core/SectionTitle";
import { Form } from "../../form";
import { Letter } from "../../graphics";
import { AppIcon, EmailIcon, LocationPinIcon } from "../../icons";
import { ContactsRow } from "./ContactsRow";
import { SocialIconsRow } from "./SocialIconsRow";

type ContactType = "email" | "location";

export const contactIconsMap: Record<ContactType, ReactNode> = {
  email: <EmailIcon />,
  location: <LocationPinIcon width="5rem" />,
};

interface Contact {
  iconKey: AppIcon;
  text: string;
}

export interface ContactSectionData {
  sectionId: string;
  title: string;
  subtitle: string;
  contacts: {
    title: string;
    items: Contact[];
  };
  social: {
    title: string;
    items: AppLink[];
  };
}

export type ContactSectionProps = ContactSectionData;

export const ContactSection = (props: ContactSectionProps) => {
  const { sectionId, title, subtitle, contacts, social } = props;

  return (
    <Section sectionId={sectionId}>
      <Grid container={true}>
        <Grid item={true} xs={12} md={6}>
          <SectionTitle title={title} subtitle={subtitle} Stroke={<Letter.C />} />
          <Box display="flex" marginTop={8} flexDirection="column">
            <Typography variant="h3">{contacts.title}</Typography>
            <ContactsRow items={contacts.items} />
          </Box>
          <Box marginTop={5}>
            <Typography variant="h3">{social.title}</Typography>
            <SocialIconsRow marginTop={3} links={social.items} />
          </Box>
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <Box marginTop={{ xs: 4, md: 0 }}>
            <Form type="contact" />
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
};
