import React from "react";
import { Character3D, Environment3D, Gun3D, Illustration2D, Vehicle3D } from "../components/graphics";
import type { AboutSectionData } from "../components/sections/About";
import type { BannerSectionData } from "../components/sections/Banner";
import type { CardsRowSectionData } from "../components/sections/CardsRow";
import type { ContactSectionProps } from "../components/sections/Contact";
import type { ExpertisesSectionData } from "../components/sections/Expertises";
import type { PartnersSectionData } from "../components/sections/Partners";
import { socialLinks } from "./links";

const bannerData: BannerSectionData = {
  autoPlay: true,
  slideDuration: 8000,
  featuredLink: socialLinks.artStation,
};

const cardsRowData: CardsRowSectionData = {
  speed: 20, // px per second
  direction: "left",
  pauseOnHover: false,
  pauseOnClick: false,
};

const aboutData: AboutSectionData = {
  sectionId: "about",
  title: "About",
  subtitle: "Studio",
  text: "DeltaRaccoons Team is an independent game art development company based in Tallinn, Estonia. The company implements art solutions for any stage of game development. Maximum quality with the shortest terms and positive interaction are the main goals of the DeltaRaccoons Team.",
  points: [
    { count: 30, title: "Projects" },
    { count: 25, title: "Artists" },
    { count: 5, title: "Years in industry" },
    { count: 15, title: "Clients" },
  ],
};

const expertisesData: ExpertisesSectionData = {
  sectionId: "expertises",
  title: "What",
  subtitle: "We do",
  items: [
    {
      title: "3D Characters",
      description: "Development of 3D characters in any style and pipeline",
      Icon: <Character3D />,
    },
    {
      title: "3D Environment",
      description: "Props creation and level design",
      Icon: <Environment3D />,
    },
    {
      title: "3D Vehicles",
      description: "Precise modeling of any type of vehicles",
      Icon: <Vehicle3D />,
    },
    {
      title: "3D Weapons",
      description: "Creating weapons with any level of complexity",
      Icon: <Gun3D />,
    },
    {
      title: "2D Concept",
      description: "Concept and design development. Creating illustrations",
      Icon: <Illustration2D />,
    },
  ],
};

const partnersData: PartnersSectionData = {
  sectionId: "partners",
  title: "Partners",
  logosPerSlice: 4,
};

const contactData: ContactSectionProps = {
  sectionId: "contacts",
  title: "Contact",
  subtitle: "Us",
  contacts: {
    title: "Our contacts",
    items: [
      {
        text: "contact@deltaraccoons.com",
        iconKey: "email",
      },
      {
        text: "Estonia, Tallinn, Kesklinna linnaosa,\nVesivärava tn 50-201, 10152",
        iconKey: "location-pin",
      },
      {
        text: `Ukraine, Vinnytsia,\nVinnytsia Oblast, Yunosti Ave, 18, 21000`,
        iconKey: "location-pin",
      },
    ],
  },
  social: {
    title: "Follow us",
    items: [
      {
        ...socialLinks.artStation,
        iconKey: "art-station",
      },
      // {
      //   ...socialLinks.instagram,
      //   iconKey: "instagram",
      // },
      {
        ...socialLinks.linkedIn,
        iconKey: "linkedin",
      },
      // {
      //   ...socialLinks.twitter,
      //   iconKey: "twitter",
      // },
    ],
  },
};

export const HomepageData = {
  bannerData,
  cardsRowData,
  aboutData,
  expertisesData,
  partnersData,
  contactData,
};
