import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { Section } from "../../core/Section";
import { SectionTitle } from "../../core/SectionTitle";
import { Letter, Shape } from "../../graphics";
import { AboutPointProps, AboutPoint } from "./Point";

export interface AboutSectionData {
  sectionId: string;
  title: string;
  subtitle: string;
  text: string;
  points: AboutPointProps[];
}

export type AboutSectionProps = AboutSectionData;

export const AboutSection = (props: AboutSectionProps) => {
  const { sectionId, title, subtitle, text, points } = props;

  return (
    <Section sectionId={sectionId}>
      <Grid container={true} sx={{ position: "relative", marginTop: { xs: 4, md: 10 } }}>
        <Grid item={true} xs={12} md={6} marginBottom={{ xs: 5, md: 0 }}>
          <SectionTitle
            component="h1"
            title={title}
            subtitle={subtitle}
            Stroke={<Letter.A />}
            strokeContainerProps={{ top: { xs: "-2rem", md: "-7.5rem " } }}
          />
          <Typography variant="body1" marginTop={4.5}>
            {text}
          </Typography>
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <Grid container={true} rowSpacing={7}>
            {points.map((point, idx) => (
              <Grid key={idx} item={true} xs={6} zIndex={1} textAlign="center">
                <AboutPoint {...point} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Box display={{ xs: "none", lg: "block" }} position="absolute" top="-70px" right="-250px" width="36rem">
          <Shape.Cube />
        </Box>
      </Grid>
    </Section>
  );
};
