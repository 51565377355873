import Box from "@mui/material/Box";
import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useMeasure } from "react-use";

const MotionBox = motion(Box);

const getTransformXOffset = (element: HTMLDivElement) => {
  const transform = getComputedStyle(element).transform;
  const positionCoords = transform.substring(transform.indexOf("(") + 1, transform.indexOf(")")).split(",");
  const xOffset = Number.parseFloat(positionCoords[4]);

  return xOffset;
};

export interface ProgressBarProps {
  progressKey?: number;
  accent?: string;
  barHeight?: number;
  duration: number;
  pause?: boolean;
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { progressKey, accent = "primary.main", barHeight = 5, duration, pause = false } = props;

  const controls = useAnimation();

  const [barPosition, setBarPosition] = useState(0);
  const [isReady, setIsReady] = useState(false);

  const [containerRef, { width: barWidth }] = useMeasure<HTMLDivElement>();
  const barRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isReady && barWidth > 0) {
      setIsReady(true);
    }
  }, [isReady, barWidth]);

  useEffect(() => {
    if (isReady) {
      controls.start({
        x: [-barWidth, 0],
      });
    }

    return () => controls.stop();
  }, [controls, barWidth, isReady]);

  useEffect(() => {
    controls.start({ x: [-barWidth, 0] });
  }, [controls, progressKey, barWidth, duration]);

  useEffect(() => {
    if (pause) {
      controls.stop();

      // save current position so progress could be resumed from it
      if (barRef.current) {
        const xOffset = getTransformXOffset(barRef.current);
        setBarPosition(xOffset);
      }
    } else {
      //
      controls.start({ x: [barPosition, 0] });
    }

    return () => controls.stop();
  }, [controls, pause, barPosition]);

  return (
    <>
      <Box
        ref={containerRef}
        sx={{ width: "100%", overflow: "hidden", position: "relative", pointerEvents: "none" }}
        width={barWidth}
        height={barHeight}
      >
        {isReady && (
          <MotionBox
            ref={barRef}
            sx={{ position: "absolute", top: 0, width: "100%", height: "100%", bgcolor: accent }}
            initial={{ x: -barWidth }}
            animate={controls}
            transition={{ duration: duration / 1000, ease: "linear" }}
          />
        )}
      </Box>
    </>
  );
};
