import { motion, PanInfo } from "framer-motion";
import React, { PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { useEffectOnce } from "react-use";
import { isBrowser } from "../../../misc/utils";
import { NavigateDirection } from "../../../types";

export interface CarouselItemProps {
  direction: NavigateDirection;
  onItemHeightChange?: (value: number) => void;
  onSwipe?: (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void;
}

export const CarouselItem = (props: PropsWithChildren<CarouselItemProps>) => {
  const { direction, onItemHeightChange, onSwipe, children } = props;

  const itemRef = useRef<HTMLDivElement>(null);

  const updateContainerHeight = useCallback(() => {
    if (itemRef.current) {
      onItemHeightChange?.(itemRef.current.offsetHeight);
    }
  }, [onItemHeightChange]);

  useEffect(() => {
    window.addEventListener("resize", updateContainerHeight);

    return () => {
      window.removeEventListener("resize", updateContainerHeight);
    };
  });

  useEffectOnce(() => {
    updateContainerHeight();
  });

  return (
    <motion.div
      ref={itemRef}
      style={{ width: "100%", ...(isBrowser && { position: "absolute" }) }}
      custom={direction}
      variants={{
        enter: (direction: NavigateDirection) => {
          return {
            x: direction === "next" ? 1000 : -1000,
            opacity: 0,
          };
        },
        center: {
          zIndex: 1,
          x: 0,
          opacity: 1,
        },
        exit: (direction: NavigateDirection) => {
          return {
            zIndex: 0,
            x: direction === "prev" ? 1000 : -1000,
            opacity: 0,
          };
        },
      }}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      }}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={1}
      onDragEnd={onSwipe}
    >
      {children}
    </motion.div>
  );
};
