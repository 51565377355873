import Box from "@mui/material/Box";
import React from "react";
import Marquee from "react-fast-marquee";
import { Card } from "./Card";

export interface CardsRowSectionData {
  speed: number;
  direction: "left" | "right";
  pauseOnHover: boolean;
  pauseOnClick: boolean;
}

export interface CardsRowSectionProps extends CardsRowSectionData {
  images: {
    name: string;
    data: Queries.ImageSharp;
  }[];
}

export const CardsRowSection = (props: CardsRowSectionProps) => {
  const { speed, direction, pauseOnHover, pauseOnClick, images } = props;

  return (
    <Box component="section">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Card image={images[0].data} sx={{ visibility: "hidden", width: "1px" }} />
        <Marquee
          direction={direction}
          gradient={false}
          speed={speed}
          pauseOnHover={pauseOnHover}
          pauseOnClick={pauseOnClick}
        >
          {images.map((image, idx) => (
            <Card key={idx} name={image.name} image={image.data} />
          ))}
        </Marquee>
      </Box>
    </Box>
  );
};
