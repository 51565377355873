import Box from "@mui/material/Box";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { AppLink } from "../../../types";
import { BannerCarousel } from "./BannerCarousel";

export interface BannerSectionData {
  autoPlay: boolean;
  slideDuration: number;
  featuredLink?: AppLink;
}

export type BannerSectionProps = BannerSectionData;

export const BannerSection = (props: BannerSectionProps) => {
  const { autoPlay = true, featuredLink, slideDuration } = props;

  const { xs, md } = useStaticQuery<Queries.BannerImagesQuery>(graphql`
    query BannerImages {
      xs: allFile(filter: { sourceInstanceName: { eq: "banner-mobile" } }, sort: { fields: name }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      md: allFile(filter: { sourceInstanceName: { eq: "banner-desktop" } }, sort: { fields: name }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  const mobileImages = xs.nodes
    .map((node) => ({
      name: node.name,
      image: node.childImageSharp as Queries.ImageSharp,
    }))
    .filter((item) => item.image);

  const desktopImages = md.nodes
    .map((node) => ({
      name: node.name,
      image: node.childImageSharp as Queries.ImageSharp,
    }))
    .filter((item) => item.image);

  return (
    <>
      <Box display={{ xs: "block", md: "none" }}>
        <BannerCarousel
          autoPlay={autoPlay}
          slideDuration={slideDuration}
          featuredLink={featuredLink}
          images={mobileImages}
        />
      </Box>
      <Box display={{ xs: "none", md: "block" }}>
        <BannerCarousel
          autoPlay={autoPlay}
          slideDuration={slideDuration}
          featuredLink={featuredLink}
          images={desktopImages}
        />
      </Box>
    </>
  );
};
