import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import React, { ReactNode } from "react";

export interface ExpertiseItemData {
  title: string;
  description: string;
  Icon: ReactNode;
}

export type ExpertiseItemProps = ExpertiseItemData;

export const ExpertiseItem = (props: ExpertiseItemProps) => {
  const { title, description, Icon } = props;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" marginTop={{ xs: 5, md: 7 }}>
      <Box
        display="flex"
        alignItems="flex-end"
        width={{ xs: "100px", md: "92px" }}
        height={{ xs: "100px", md: "92px" }}
        sx={{ svg: { width: "100%" } }}
      >
        {Icon}
      </Box>
      <Typography variant="h3" whiteSpace="pre-wrap" marginTop={2.5}>
        {title}
      </Typography>
      <Typography variant="body1" whiteSpace="pre-wrap" marginTop={1}>
        {description}
      </Typography>
    </Box>
  );
};
