import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box, { BoxProps } from "@mui/material/Box";
import { AnimatePresence, PanInfo } from "framer-motion";
import { wrap } from "popmotion";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useInterval } from "react-use";
import { useSwipe } from "../../../hooks/useSwipe";
import { useTimer } from "../../../hooks/useTimer";
import { NavigateDirection } from "../../../types";
import { ProgressBar } from "../ProgressBar";
import { Indicators } from "./Indicator";
import { CarouselItem } from "./Item";
import { CarouselNavButton } from "./NavButton";

type VisibilityMode = "always" | "onHover" | "never";

export const isItemVisible = (mode: VisibilityMode, isContainerHovered: boolean) => {
  switch (mode) {
    case "always":
      return true;
    case "never":
      return false;
    case "onHover":
      return isContainerHovered;
    default:
      return false;
  }
};
export interface CarouselProps extends Pick<BoxProps, "sx"> {
  showIndicators?: VisibilityMode;
  showNavigationButtons?: VisibilityMode;

  autoplay?: boolean;
  stopAutoplayOnHover?: boolean;
  intervalDuration?: number;

  showProgressBar?: boolean;

  children?: ReactNode[];
}

export const Carousel = (props: CarouselProps) => {
  const {
    showIndicators = "always",
    showNavigationButtons = "always",
    autoplay = true,
    stopAutoplayOnHover = true,
    intervalDuration = 4000,
    showProgressBar = true,
    children = [],
    sx,
  } = props;

  const { elapsedMs, start, pause, resume } = useTimer();

  const [[page, direction], setPage] = useState<[number, NavigateDirection]>([0, "initial"]);
  const [itemHeight, setItemHeight] = useState<number>(0);
  const [hovered, setHovered] = useState(false);
  const [isAutoplayEnabled, setIsAutoplayEnabled] = useState(autoplay);

  const shouldStopAutoplayOnHover = stopAutoplayOnHover && hovered;
  const duration = isAutoplayEnabled ? intervalDuration - elapsedMs : 0;

  const { handleSwipe } = useSwipe();

  const itemIndex = wrap(0, children.length, page);

  const shouldShowIndicators = isItemVisible(showIndicators, hovered);
  const shouldShowNavigation = isItemVisible(showNavigationButtons, hovered);

  const handleSetPage = useCallback(
    (direction: NavigateDirection) => {
      const newPage = page + (direction === "next" ? 1 : -1);

      setPage([newPage, direction]);
    },
    [page],
  );

  const disableAutoplay = useCallback(() => {
    if (isAutoplayEnabled) {
      setIsAutoplayEnabled(false);
    }
  }, [isAutoplayEnabled]);

  const handleNavigationButtonClick = useCallback(
    (direction: NavigateDirection) => () => {
      disableAutoplay();
      handleSetPage(direction);
    },
    [disableAutoplay, handleSetPage],
  );

  const handleIndicatorClick = useCallback(
    (idx: number) => {
      disableAutoplay();
      const direction = idx > itemIndex ? "next" : "prev";
      setPage([idx, direction]);
    },
    [itemIndex, disableAutoplay],
  );

  const handleCarouselItemSwipe = useCallback(
    (_, { offset, velocity }: PanInfo) => {
      disableAutoplay();

      const swipeDirection = handleSwipe(offset.x, velocity.x);

      if (swipeDirection !== "initial") {
        handleSetPage(swipeDirection);
      }
    },
    [disableAutoplay, handleSwipe, handleSetPage],
  );

  const handleSetCarouselMouseHover = (shouldStop: boolean) => () => {
    setHovered(shouldStop);
  };

  useEffect(() => {
    start();
  }, [start, itemIndex]);

  useEffect(() => {
    if (hovered) {
      pause();
    } else {
      resume();
    }
  }, [hovered, pause, resume]);

  useInterval(
    () => {
      if (isAutoplayEnabled) {
        handleSetPage("next");
      }
    },
    shouldStopAutoplayOnHover ? null : duration,
  );

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "flexStart",
        ...(itemHeight > 0 && { height: itemHeight }),
        ...sx,
      }}
      onMouseEnter={handleSetCarouselMouseHover(true)}
      onMouseLeave={handleSetCarouselMouseHover(false)}
    >
      <AnimatePresence initial={false} custom={direction}>
        <CarouselItem
          key={page}
          direction={direction}
          onSwipe={handleCarouselItemSwipe}
          onItemHeightChange={(value) => setItemHeight(value)}
        >
          {children[itemIndex]}
        </CarouselItem>
      </AnimatePresence>
      {shouldShowNavigation && (
        <CarouselNavButton sx={{ left: 0 }} onClick={handleNavigationButtonClick("prev")}>
          <ArrowBackIosNewIcon color="primary" />
        </CarouselNavButton>
      )}
      {shouldShowNavigation && (
        <CarouselNavButton sx={{ right: 0 }} onClick={handleNavigationButtonClick("next")}>
          <ArrowForwardIosIcon color="primary" />
        </CarouselNavButton>
      )}
      {shouldShowIndicators && (
        <Indicators length={children.length} activeIndex={itemIndex} onIndicatorClick={handleIndicatorClick} />
      )}
      {showProgressBar && (
        <Box position="absolute" left={0} right={0} bottom={0} zIndex={1}>
          <ProgressBar progressKey={itemIndex} duration={duration} pause={shouldStopAutoplayOnHover} />
        </Box>
      )}
    </Box>
  );
};
