import { NavigateDirection } from "../types";

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
export function useSwipe(swipeThreshold = 10000) {
  const handleSwipe = (offset: number, velocity: number): NavigateDirection => {
    const swipedAt = Math.abs(offset) * velocity;

    if (swipedAt < -swipeThreshold) {
      return "next";
    } else if (swipedAt > swipeThreshold) {
      return "prev";
    }

    return "initial";
  };

  return { handleSwipe };
}
