import Grid from "@mui/material/Grid";
import { motion } from "framer-motion";
import React from "react";
import { Section } from "../../core/Section";
import { SectionTitle } from "../../core/SectionTitle";
import { Letter } from "../../graphics";
import { ExpertiseItem, ExpertiseItemData } from "./Item";

const MotionGridItem = motion(Grid);

export interface ExpertisesSectionData {
  sectionId: string;
  title: string;
  subtitle: string;
  items: ExpertiseItemData[];
}

export type ExpertisesSectionProps = ExpertisesSectionData;

export const ExpertisesSection = (props: ExpertisesSectionProps) => {
  const { sectionId, title, subtitle, items = [] } = props;

  return (
    <Section sectionId={sectionId}>
      <SectionTitle title={title} subtitle={subtitle} Stroke={<Letter.W />} />
      <Grid container={true} justifyContent="center" spacing={2}>
        {items.map((item, idx) => (
          <MotionGridItem
            key={idx}
            item={true}
            xs={12}
            md={4}
            initial={{ opacity: 0.2, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{
              amount: 0.5,
              once: true,
            }}
          >
            <ExpertiseItem title={item.title} description={item.description} Icon={item.Icon} />
          </MotionGridItem>
        ))}
      </Grid>
    </Section>
  );
};
