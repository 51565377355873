import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import React, { Fragment } from "react";
import { AppIcon, getAppIcon } from "../../icons";

interface ContactsRowProps {
  items: {
    iconKey: AppIcon;
    text: string;
  }[];
}

export const ContactsRow = (props: ContactsRowProps) => {
  const { items } = props;

  return (
    <Fragment>
      {items.map((item, idx) => (
        <Box key={idx} display="flex" flexDirection="row" marginTop={3} paddingRight={[4, 4, 10]}>
          <Box marginTop={1}>{getAppIcon(item.iconKey)}</Box>
          <Box display="flex" alignItems="center" marginLeft={3}>
            <Typography whiteSpace="pre-wrap" variant="body1">
              {item.text}
            </Typography>
          </Box>
        </Box>
      ))}
    </Fragment>
  );
};
