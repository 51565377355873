import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { random } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useCountUp } from "react-countup";
import { useIntersection } from "react-use";

export interface AboutPointProps {
  count: number;
  title: string;
}

export const AboutPoint = (props: AboutPointProps) => {
  const { title, count } = props;

  const [isCountUpDone, setIsCountUpDone] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const { start } = useCountUp({
    ref: elementRef,
    start: 0,
    startOnMount: false,
    end: count,
    duration: random(1.5, 2, true),
  });

  const intersection = useIntersection(elementRef, {
    rootMargin: "-10px 0px",
    threshold: 1,
  });
  const isIntersecting = intersection?.isIntersecting ?? false;

  useEffect(() => {
    if (isIntersecting && !isCountUpDone) {
      start();
      setIsCountUpDone(true);
    }
  }, [isIntersecting, isCountUpDone, start]);

  return (
    <Typography variant="h2" component="h3" fontWeight="bold" color="text.primary">
      <Box component="span" ref={elementRef}>
        0
      </Box>
      <Box display="block" component="small" fontSize="1.75rem">
        {title}
      </Box>
    </Typography>
  );
};
