import { useCallback, useRef, useState } from "react";

export function useTimer() {
  const startTimestamp = useRef(0);
  const [elapsedMs, setElapsedMs] = useState(0);

  const start = useCallback(() => {
    setElapsedMs(0);
    startTimestamp.current = Date.now();
  }, []);

  const pause = useCallback(() => {
    setElapsedMs((prev) => prev + Date.now() - startTimestamp.current);
  }, []);

  const resume = useCallback(() => {
    startTimestamp.current = Date.now();
  }, []);

  return {
    elapsedMs,
    start,
    pause,
    resume,
  };
}
