import Box from "@mui/material/Box";
import React from "react";
import { AppLink } from "../../../types";
import { Button } from "../../core/Button";
import { Carousel } from "../../core/Carousel";
import { Image } from "../../core/Image";
import { SmartLink } from "../../core/SmartLink";

interface Image {
  name: string;
  image: Queries.ImageSharp;
}

export interface DesktopCarouselProps {
  autoPlay?: boolean;
  slideDuration: number;
  featuredLink?: AppLink;
  images: Image[];
}

export const BannerCarousel = (props: DesktopCarouselProps) => {
  const { autoPlay = true, featuredLink, slideDuration, images } = props;

  return (
    <Box position="relative">
      <Carousel
        showIndicators="onHover"
        showNavigationButtons="onHover"
        autoplay={autoPlay}
        intervalDuration={slideDuration}
      >
        {images.map(({ name, image }) => (
          <Box key={name} position="relative">
            <Image image={image.gatsbyImageData} alt={name} />
            {featuredLink && (
              <Box display={{ xs: "none", md: "block" }}>
                <SmartLink
                  path={featuredLink.path}
                  external={featuredLink.external}
                  sx={{
                    display: { xs: "none", md: "block" },
                    position: "absolute",
                    bottom: "12%",
                    left: "2%",
                    zIndex: 1,
                  }}
                >
                  <Button color="primary" variant="outlined">
                    {featuredLink.label}
                  </Button>
                </SmartLink>
              </Box>
            )}
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};
