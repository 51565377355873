import IconButton from "@mui/material/IconButton";
import { SxProps } from "@mui/system";
import Box from "@mui/system/Box";
import React from "react";
import { IconIndicator } from "../../icons";

interface IndicatorProps {
  length: number;
  activeIndex: number;
  onIndicatorClick: (index: number) => void;
  indicatorContainerProps?: {
    sx?: SxProps;
  };
}

export const Indicators = (props: IndicatorProps) => {
  const { length, activeIndex, onIndicatorClick, indicatorContainerProps } = props;

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 8,
        zIndex: 1,
        width: "100%",
        marginTop: "10px",
        textAlign: "center",
        ...indicatorContainerProps?.sx,
      }}
    >
      {new Array(length).fill(null).map((_, idx) => (
        <IconButton
          key={idx}
          sx={{
            cursor: "pointer",
            transition: "200ms",
            paddingY: 0,
            paddingX: 1,
            color: "transparent",
            "&:hover": {
              color: "button.hover",
            },
            "&:active": {
              color: "button.hover",
            },
            ...(activeIndex === idx && {
              color: "primary.main",
            }),
          }}
          onClick={() => {
            onIndicatorClick(idx);
          }}
          aria-label={`carousel indicator ${idx + 1}`}
        >
          <IconIndicator />
        </IconButton>
      ))}
    </Box>
  );
};
