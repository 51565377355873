import Box, { BoxProps } from "@mui/system/Box";
import React from "react";
import { AppLink } from "../../../types";
import { SmartLink } from "../../core/SmartLink";
import { getAppIcon } from "../../icons";

export interface SocialRowProps extends BoxProps {
  links: AppLink[];
}

export const SocialIconsRow = (props: SocialRowProps) => {
  const { links, ...otherProps } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }} {...otherProps}>
      {links.map((link, idx) => (
        <Box key={idx} sx={{ marginLeft: idx === 0 ? 0 : 5, fontSize: { xs: "2rem", md: "2.75rem" } }}>
          <SmartLink
            external={link.external}
            path={link.path}
            sx={{ color: "primary.main", "&:hover": { color: "primary.dark" } }}
          >
            {getAppIcon(link?.iconKey)}
          </SmartLink>
        </Box>
      ))}
    </Box>
  );
};
